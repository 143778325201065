import React from "react";
import styled from "@emotion/styled";

const Container = styled.p`
  font-size: 24px;
  font-weight: 300;
  text-decoration: none;
  span {
    font-weight: 400;
    padding: 0 0 0 0.1em;
  }
`;

export default function Logo() {
  return (
    <Container className="wf">
      Under<span>Mountain</span>
    </Container>
  );
}
